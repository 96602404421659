//libs
import React, { useState } from "react";
import logo_1 from '@assets/images/logo-1.svg';
import logo_2 from '@assets/images/logo-2.svg';
import logo_3 from '@assets/images/logo-3.svg';
import { Link, useNavigate } from "react-router-dom";
import { getCurrentLanuage, getTxt, setCurrentLanguage } from '@data/locales';

const Intro = () => {

  const [activeMenu, setActiveMenu] = useState(false);

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    window.location.reload();
  }

  return (
    <div className="intro">
      <div className="langmenu">
        <span onClick={ () => changeLanguage('en') } className={'lang ' + (getCurrentLanuage() == 'en' ? 'active' : '') }>NL</span>
        <span onClick={ () => changeLanguage('fr-BE') } className={'lang ' + (getCurrentLanuage() == 'fr-BE' ? 'active' : '') }>FR</span>
      </div>
      <div className="intro-wrapper">
        <div className="logo">
          <img className="logo-1" alt="Jorissen & De Jonck" src={ logo_1 } width="80" />
          <img className="logo-2" alt="Jorissen & De Jonck" src={ logo_2 } width="728" />
          <img className="logo-3" alt="Jorissen & De Jonck" src={ logo_3 } width="280" />
        </div>
        { !activeMenu && (
          <div className="menu-items">
            <div onClick={()=>setActiveMenu('finance')} className="btn-main">{ getTxt('mainMenu', 'finance') }</div>
            <div onClick={()=>setActiveMenu('permanent')} className="btn-main">{ getTxt('mainMenu', 'permanent') }</div>
          </div>
        )}
        { (activeMenu == 'finance') && (
          <div className="menu-items">
            <Link to="/consultants" className="btn-main">{ getTxt('mainMenu', 'consultants') }</Link>
            <Link to="/freelancers" className="btn-main">{ getTxt('mainMenu', 'freelancers') }</Link>
            <Link to="/companies" className="btn-main">{ getTxt('mainMenu', 'companies') }</Link>
          </div>
        )}
        { (activeMenu == 'permanent') && (
          <div className="menu-items">
            <Link to="/werknemers" className="btn-main">{ getTxt('mainMenu', 'talent') }</Link>
            <Link to="/werkgevers" className="btn-main">{ getTxt('mainMenu', 'employers') }</Link>
            <Link to="/werken" className="btn-main">{ getTxt('mainMenu', 'jdj') }</Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Intro;