export const locales = {
	mainMenu: {
		en: {
			talent: 'Talent',
			services: 'Onze Services',
			employers: 'Werkgevers',
			jdj: 'Werken bij J&DJ',
			jdjfor: 'Werken voor J&DJ',
			aboutus: 'Over ons',
			jobs: 'Jobs',
			vacancies: 'Vacatures',
			team: 'Onze ploeg',
			contact: 'Contact',
			terms: 'Terms & Conditions',
			privacy: 'Privacy Policy',
			cookies: 'Cookies',
			consultants: 'Consultants',
			freelancers: 'Freelancers',
			companies: 'Bedrijven',
			finance: 'Finance Project Staffing',
			permanent: 'Permanent Recruitment',
			projects: 'Projecten'
		},
		fr : {
			talent: 'Talent',
			services: 'Nos Services',
			employers: 'Employeurs',
			jdj: 'Travailler chez J&DJ',
			jdjfor: 'Werken pour J&DJ',
			aboutus: 'À propos de nous',
			jobs: 'Emplois',
			vacancies: 'Postes vacants',
			team: 'Notre équipe',
			contact: 'Contact',
			terms: 'Termes et conditions',
			privacy: 'Privacy Policy',
			cookies: 'Cookies',
			consultants: 'Consultants',
			freelancers: 'Freelancers',
			companies: 'Entreprises',
			finance: 'Personnel du projet financier',
			permanent: 'Recrutement permanent',
			projects: 'Projets'
		}
	},
	talentHome : {
		en : {
			nichesTitle: 'Onze Niches',
			servicesTitle: 'Onze Services',
			btnJobs: 'Ga naar onze jobs',
			btnCandidates: 'Bekijk onze kandidaten',
			clientsTitle: 'Onze klanten',
		},
		fr : {
			nichesTitle: 'Notre niches',
			servicesTitle: 'Nos Services',
			btnJobs: 'Accédez à nos emplois',
			btnCandidates: 'Voir nos candidats',
			clientsTitle: 'Nos clients',
		}
	},
	aboutUs : {
		en : {
			our: 'Ons',
			dna: 'DNA',
		},
		fr : {
			our: 'Notre',
			dna: 'ADN',
		}
	},
	jobs : {
		en : {
			filter: 'Filter uw ideale job',
			chooseCompany: 'Kies type bedrijf',
			typeCompany: 'Kies type bedrijf',
			chooseSpecial: 'Kies functietitel',
			choosePeriod: 'Kies arbeidsduur',
			chooseRegion: 'Kies regio',
			searchRegion: 'Zoek op regio',
			jobAlertTxt: 'Niet gevonden wat je zoekt? Geen zorg! Laat hieronder je gegevens achter en wij nemen contact op voor vacatures op maat.',
			btnJob: 'Bekijk job',
			notFound: 'Niet gevonden wat je zoekt?',
			notFoundTxt: 'Laat ons jouw profiel analyseren en ontvang vacatures op maat!',
			btnFill: 'Vul je gegevens in',
			brussel: 'Brussel',
			antwerp: 'Antwerpen',
			limburg: 'Limburg',
			luik: 'Luik',
			namen: 'Namen',
			luxemburg: 'Luxemburg',
			henegouwen: 'Henegouwen',
			west_flanders: 'West-Vlaanderen',
			east_flanders: 'Oost-Vlaanderen',
			permanent: 'Permanent',
			temporary: 'Tijdelijk'
		},
		fr : {
			filter: "Filtrez votre emploi idéal",
			chooseCompany: "Choisissez le type d'entreprise",
			typeCompany: "Choisissez le type d'entreprise",
			chooseSpecial: "Choisissez un titre de poste",
			choosePeriod: 'Choisissez la durée de travail.',
			chooseRegion: "Choisissez une région",
			searchRegion: "Recherchez par région",
			jobAlertTxt: "Vous n'avez pas trouvé ce que vous cherchez ? Pas de souci ! Laissez vos coordonnées ci-dessous et nous vous contacterons pour des offres d'emploi sur mesure.",
			btnJob: "Voir l'emploi",
			notFound: "Vous n'avez pas trouvé ce que vous cherchez ?",
			notFoundTxt: "Laissez-nous analyser votre profil et recevez des offres d'emploi sur mesure !",
			btnFill: "Remplissez coordonnées",
			brussel: "Bruxelles",
			antwerp: "Anvers",
			limburg: "Limbourg",
			luik: "Liège",
			namen: "Namur",
			luxemburg: "Luxembourg",
			henegouwen: "Hainaut",
			west_flanders: "Flandre occidentale",
			east_flanders: "Flandre orientale",
			permanent: 'Permanent',
			temporary: 'Temporaire'
		}
	},
	forms: {
		en: {
			thanks: 'Bedankt, we contacteren u zo spoedig mogelijk',
			firstName: 'Voornaam',
			lastName: 'Achternaam',
			email: 'E-mailadres',
			phone: 'Telefoonnummer',
			company: 'Bedrijfsnaam',
			full_name: 'Volledige Naam',
			message: 'Uw bericht',
			firstOne: 'Wil je als eerste op de hoogte zijn van de beste vacatures binnen jouw vakgebied? Laat hieronder je gegevens achter en wij nemen contact op voor vacatures op maat!',
			links: 'Links (LinkedIn, Google Drive of online CV)*',
			sector: 'Sector',
			construction: 'Construction',
			finance: 'Finance',
			engineering: 'Engineering',
			function: 'Functietitel',
			activein: 'Actief binnen',
			interest: 'Interesse in jobs bij',
			mobile: 'Gsm nummer',
			vacancy_type: 'Vakgebied vacature',
			send: 'Verzenden',
			error: 'Gelieve alle velden correct in te vullen',
			vast_job: 'Vaste Job',
			student_job: 'Studentenjob',
			stage: 'Stage'
		},
		fr: {
			thanks: "Merci, nous vous contacterons dès que possible",
			firstName: "Prénom",
			lastName: "Nom de famille",
			email: "Adresse e-mail",
			phone: "Numéro de téléphone",
			company: "Nom de l'entreprise",
			full_name: "Nom complet",
			message: "Votre message",
			firstOne: "Voulez-vous être informé en premier des meilleures offres d'emploi dans votre domaine ? Laissez vos coordonnées ci-dessous et nous vous contacterons pour des offres d'emploi sur mesure !",
			links: "Liens (LinkedIn, Google Drive ou CV en ligne)*",
			sector: "Secteur",
			construction: "Construction",
			finance: "Finance",
			engineering: "Ingénierie",
			function: "Titre de poste",
			activein: "Actif dans",
			interest: "Intérêt pour les emplois chez",
			mobile: "Numéro de portable",
			vacancy_type: "Domaine de poste",
			send: 'Envoyez',
			error: 'Veuillez remplir tous les champs correctement',
			vast_job: 'Emploi permanent',
			student_job: "Job d'étudiant",
			stage: 'Stage'
		}
	},
	team: {
		en: {
			makeAppointment: 'Maak een afspraak',
			workWithUs: 'Werk samen met ons',
			launch: 'Lanceer nu',
			boost: 'Boost je',
			vacature: 'vacature',
			carriere: 'carrière',
			here: ' hier !',
			launchyour: 'Lanceer je',
			workHere: 'Kom bij ons werken',
			finance: 'Finance',
			construction: 'Construction',
			engineering: 'Engineering',
			backoffice: 'Backoffice',
			interimmanagementconsulting: 'Interim management & Consulting',
			founders: 'Founders'			
		},
		fr: {
			makeAppointment: "Prendre rendez-vous",
			workWithUs: "Travailler avec nous",
			launch: "Lancer maintenant",
			boost: 'Boostez-vous',
			vacature: 'poste vacant',
			carriere: 'carrière',
			here: ' ici !',
			launchyour: 'Lancez-vous',
			workHere: "Travailler ici",
			finance: 'Finance',
			construction: 'Construction',
			engineering: 'Ingénierie',
			backoffice: 'Back-office',
			interimmanagementconsulting: 'Gestion intérimaire et conseil',
			founders: 'Fondateurs'		
		}
	},
	contact: {
		en: {
			werknemers: 'Onze Consultants inschakelen om u te koppelen aan de beste bedrijven uit ons netwerk? Of wil u graag volledig vrijblijvend uw carrière laten analyseren? Let’s get in touch! ',
			werkgevers: 'Onze Consultants inschakelen om uw meest complexe vacatures in te vullen? Of wil u graag meer informatie over onze manier van werken? Let’s get in touch!',
			werken: 'Carrière maken bij Jorissen & De Jonck? Dat avontuur start hier. Solliciteer spontaan via het contactformulier of duik meteen in onze vacatures en verover een plaats in de Beste Ploeg van ’t Stad.',
			intouch: "Let's get in touch",
			places: 'Onze vestigingen',
			contactus: 'Contacteer ons',
			contact: 'Contacteer',
			us: 'ons',
			mainseat: 'Hoofdzetel'
		},
		fr: {
			werknemers: 'Voulez-vous faire appel à nos Consultants pour vous mettre en relation avec les meilleures entreprises de notre réseau? Où souhaitez-vous faire analyser votre carrière sans engagement? Contactez-nous!',
			werkgevers: "Voulez-vous faire appel à nos Consultants pour pourvoir vos postes vacants les plus complexes ? Ou souhaitez-vous obtenir plus d'information sur notre façon de travailler ? Contactez-nous! ",
			werken: "Faire carrière chez Jorissen & De Jonck ? L'aventure commence ici. Postulez spontanément via le formulaire de contact ou plongez directement dans nos offres d'emploi et conquérez une place dans la Meilleure Équipe d'Anvers.",
			intouch: "Let's get in touch",
			places: 'Notre établissement',
			contactus: 'Contactez-nous',
			contact: 'Contactez',
			us: '-nous',
			mainseat: 'Siège principal'
		}
	},
	workHome: {
		en: {
			applyNow: 'Solliciteer nu',
			open: 'Open',
			positions: 'posities',
			positionsTxt: 'Solliciteer nu voor één van onze openstaande vacatures!',
			btnVacancy: 'Bekijk vacature',
			what: 'Wat zegt',
			ourteam: 'onze ploeg'
		},
		fr: {
			applyNow: "Postuler maintenant",
			open: "Ouvert",
			positions: "postes",
			positionsTxt: "Postulez dès maintenant pour l'un de nos postes vacants !",
			btnVacancy: "Voir l'offre d'emploi",
			what: "Qu'est-ce que dit",
			ourteam: "notre équipe"
		}
	},
	positions: {
		en: {
			open: 'Open posities',
			openTxt: 'Solliciteer nu voor één van onze openstaande vacatures!',
			watch: 'Bekijk vacature',
			come: 'Kom bij ons werken',
			btnAppointment: 'Maak afspraak',
			launch: 'Lanceer je',
			carriere: 'carrière',
			here: 'hier !',
		},
		fr: {
			open: "Postes ouverts",
			openTxt: "Postulez dès maintenant pour l'un de nos postes vacants !",
			watch: "Voir l'offre d'emploi",
			come: "Venez travailler avec nous",
			btnAppointment: "Prendre rendez-vous",
			launch: 'Lancez-vous',
			carriere: 'carrière',
			here: 'ici !',
		}
	},
	positionsDetails: {
		en: {
			title1: 'Team tactics',
			description1 : 'Sinds 2016 staan we bekend als de nieuwste sensatie in de Antwerpse rekruteringswereld. We spelen non-stop Champions League en daar zitten deze 5 team tactics zeker voor iets tussen.',
			title2: 'Toon ambitie.',
			description2: 'Want wie zónder zit, valt naast de ploeg. Ambitie is het vuur dat brandt in ieder van ons en de bal aan het rollen houdt binnen onze ploeg. Wij streven ernaar het beste rekruteringskantoor op de markt te zijn. Voor minder komen wij ons bed niet uit – behalve als de Belgen spelen, want dan kijken we met heel de ploeg!',
			title3: 'Blijf in beweging.',
			description3: 'Onze ploeg kent maar één versnelling: de zesde. Op ons HQ gonst het dan ook van de bedrijvigheid. Voor passiviteit is er bij ons geen plaats en snel schakelen is de boodschap. We zijn jong en we willen wat. Gemiddeld 26 jaar jong om precies te zien. Bruisen doen we dan ook als een frisse gin tonic op een zwoele zomeravond. Verschrikkelijk cliché, maar je begrijpt het wel.',
			title4: 'Geniet van ieder moment.',
			description4: 'Naast jobs in finance en bouw hebben we bij Jorissen & De Jonck aardig wat expertise opgebouwd in een derde niche: entertainment. Daar matchen we vooral onze eigen collega’s met leuke activiteiten in binnen- en buitenland. Successen worden uitgebreid gevierd, tegenslagen gedeeld en tegelijk ook diep gekoesterd. Je leeft per slot van rekening maar één keer en bij Jorissen & De Jonck is dat aan 200%.',
			title5: 'Bekijk tegenslagen als kansen.',
			description5: 'In ons team alleen maar doorzetters, hét kenmerk van een goede recruiter. Een persoonlijk netwerk opbouwen gaat dan ook altijd gepaard met ups &amp; downs, terwijl het pas rendeert wanneer je het goed weet te onderhouden. Met die Growth Mindset in het achterhoofd schop je het bij Jorissen & De Jonck tot een ware kampioen!',
			title6: 'Wees assertief.',
			description6: 'Wie bij Jorissen  De Jonck aan de slag kan, heeft talent. Het zijn mensen die in staat zijn om opportuniteiten te zien vóór ze zich voordoen. Individuen die méér zien dan een ander, die durven anticiperen en een spatje proactiviteit belangrijker vinden dan een zee van tijd. Hebben we jou net omschreven? Aarzel dan niet.',
			title7: 'Hoe jij je in de ploeg kan spelen',
			title8: 'Babbelen',
			description8: 'Bellen en babbelen, daar zijn we fan van. Je wordt dan ook in eerste instantie telefonisch gecontacteerd door onze Talent Manager. Zij is een kei in kennismaken en voelt meteen of er een klik kan zijn met onze ploeg.',
			title9: 'Koffie Drinken',
			description9: 'Wanneer je goed gebabbeld hebt, word je uitgenodigd in wat misschien wel je tweede thuis wordt. We zijn namelijk benieuwd wat je van onze koffie vindt en dus drinken we graag een kopje samen. Tussendoor zal er ook nog een interview afgenomen worden. Zo leren wij jou wat beter kennen. Tijdens de gebruikelijke rondleiding kan je bovendien al eens een praatje slaan met je nieuwe beste vrienden.',
			title10: 'Manddekking spelen',
			description10: 'In de derde fase van het rekruteringsproces tonen we jou de actie. Je krijgt van ons enkele opdrachten die je laten zien wat de job precies inhoudt. Aan jou om ons te tonen hoe je het zou aanpakken. Daarnaast speel je even mandekking op één van onze collega’s: je volgt telefoongesprekken nauwlettend mee op en je krijgt uit eerste hand extra informatie over hoe collega’s met dezelfde functie de job precies invullen. Zo krijg je een volledig beeld over onze ploeg en de job.',
			title11: 'Matchfixen',
			description11: 'Tijd om de deal te sluiten. Als wij ons werk goed gedaan hebben, zijn we op dit punt overtuigd dat jij een plek in onze ploeg verdient. We schuiven jou dan ook met veel plezier een contractvoorstel onder de neus. Zodra je het ondertekent, heten we jou met veel toeters en bellen welkom bij De Beste Ploeg van ’t Stad!',
			soli: 'Solliciteer voor'
		},
		fr: {
			title1: "Tactiques d'équipe",
			description1: "Depuis 2016, nous sommes connus comme la sensation la plus récente dans le monde du recrutement à Anvers. Nous jouons en permanence en Ligue des Champions et ces 5 tactiques d'équipe y sont certainement pour quelque chose.",
			title2: "Affichez de l'ambition.",
			description2: "Parce que celui qui n'en a pas se retrouve à l'écart de l'équipe. L'ambition est le feu qui brûle en chacun de nous et qui maintient le ballon en mouvement au sein de notre équipe. Nous aspirons à être la meilleure agence de recrutement sur le marché. Nous ne nous levons pas pour moins que cela - sauf quand les Belges jouent, car alors nous regardons tous ensemble !",
			title3: "Restez en mouvement.",
			description3: "Notre équipe n'a qu'une seule vitesse : la sixième. Notre siège social est donc constamment animé. Il n'y a pas de place pour la passivité chez nous et la rapidité est essentielle. Nous sommes jeunes et nous voulons ce que nous voulons. En moyenne, nous avons 26 ans. Nous bouillonnons comme un gin tonic frais lors d'une chaude soirée d'été. Terriblement cliché, mais vous comprenez.",
			title4: "Profitez de chaque moment.",
			description4: "Outre les emplois dans les domaines de la finance et de la construction, nous avons également acquis une expertise considérable chez Jorissen & De Jonck dans un troisième créneau : le divertissement. Nous organisons des activités amusantes en Belgique et à l'étranger, en particulier pour nos propres collègues. Les succès sont célébrés en grand, les revers partagés et chéris en même temps. Après tout, on ne vit qu'une fois et chez Jorissen & De Jonck, on le vit à 200%.",
			title5: "Considérez les revers comme des opportunités.",
			description5: "Dans notre équipe, seuls les persévérants sont présents, la marque d'un bon recruteur. Construire un réseau personnel comporte des hauts et des bas, mais il ne porte ses fruits que si vous savez l'entretenir correctement. Avec cette mentalité de croissance, vous deviendrez un véritable champion chez Jorissen & De Jonck !",
			title6: "Soyez assertif.",
			description6: "Si vous pouvez rejoindre Jorissen & De Jonck, cela signifie que vous avez du talent. Ce sont des personnes capables de voir des opportunités avant qu'elles ne se présentent. Des individus qui voient plus loin que les autres, qui osent anticiper et pour qui un peu de proactivité est plus important qu'une mer de temps. Si cela vous décrit, n'hésitez pas.",
			title7: "Comment vous intégrer dans l'équipe",
			title8: "Discuter",
			description8: "Nous aimons les appels et les discussions. Vous serez donc d'abord contacté par notre gestionnaire des talents par téléphone. Elle excelle dans les présentations et sent immédiatement si un lien peut être établi avec notre équipe.",
			title9: "Prendre un café",
			description9: "Si la conversation téléphonique s'est bien déroulée, vous serez invité dans ce qui pourrait devenir votre deuxième foyer. Nous sommes curieux de savoir ce que vous pensez de notre café, donc nous aimerions prendre une tasse ensemble. En cours de route, nous vous ferons passer un entretien pour mieux vous connaître. Lors de la visite habituelle, vous pourrez également discuter avec vos nouveaux meilleurs amis.",
			title10: "Jouer en couverture",
			description10: "Dans la troisième phase du processus de recrutement, nous vous montrerons l'action. Nous vous donnerons quelques tâches pour vous montrer exactement ce que le poste implique. À vous de nous montrer comment vous vous y prendriez. De plus, vous jouerez un moment en couverture avec l'un de nos collègues : vous suivrez attentivement les conversations téléphoniques et obtiendrez des informations supplémentaires de première main sur la façon dont les collègues occupant le même poste remplissent exactement leurs fonctions. Vous obtiendrez ainsi une vision complète de notre équipe et du poste.",
			title11: "Finaliser l'accord",
			description11: "Il est temps de conclure l'accord. Si nous avons bien fait notre travail, nous sommes convaincus à ce stade que vous méritez une place dans notre équipe. Nous vous présenterons avec plaisir une proposition de contrat. Dès que vous l'aurez signée, nous vous souhaiterons la bienvenue avec beaucoup de joie dans la Meilleure Équipe de la Ville !",
			soli: 'Poser sa candidature'
		}
	},
	cookies: {
		en: {
			title: 'Jorissen & De Jonck maakt gebruik van cookies',
			text: 'Onze website maakt gebruik van cookies. Cookies hebben voornamelijk als doel om uw ervaring op onze website te verbeteren. Sommige cookies zijn noodzakelijk om onze website te laten functioneren en worden daarom automatisch geplaatst. Daarnaast gebruiken we statistische, voorkeuren en social media/marketing cookies. Deze cookies plaatsen we enkel indien u voorafgaand uw toestemming daarvoor hebt gegeven. ',
			more: 'Meer informatie vindt u in onze',
			link: 'Cookie policy',
			accept: 'Ik aanvaard alle cookies ',
			decline: 'Ik weiger alle cookies',
		},
		fr: {
			title: 'Jorissen & De Jonck utilise des cookies',
			text: "Notre site web utilise des cookies. Les cookies ont principalement pour objectif d'améliorer votre expérience sur notre site. Certains cookies sont nécessaires au bon fonctionnement de notre site et sont donc placés automatiquement. De plus, nous utilisons des cookies statistiques, de préférences et de médias sociaux/marketing. Nous plaçons ces cookies uniquement si vous avez donné votre consentement préalable.",
			more: "Vous trouverez plus d'informations dans notre",
			link: 'politique en matière de cookies',
			accept: "J'accepte tous les cookies. ",
			decline: "Je refuse tous les cookies.",
		}
	}

};

