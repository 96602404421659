//libs
import React, { useState, useEffect, useRef } from 'react';
import logo from '@assets/images/logo.svg';
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import Loading from "@components/Loading";
import moment from 'moment';
import Member from "@components/Member";
import MainButton from "@components/MainButton";
import astronaut from '@assets/images/not-found.png';
import axios from 'axios';
import _ from 'underscore';
import JobalertForm from "@components/JobalertForm";
import Select from 'react-select';
import Tickbox from "@components/Tickbox";
import { getCurrentLanuage, getTxt } from '@data/locales';

const Jobs = ({ section }) => {

  const [content, setContent] = useState(false);
  const [jobs, setJobs] = useState(false);
  const [expert, setExpert] = useState(false);
  const [email, setEmail] = useState('');
  const form = useRef();

  const [niches, setNiches] = useState([]);
  const [subniches, setSubniches] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);

  const [nicheFilter, setNicheFilter] = useState(false);

  const [permanentFilter, setPermanentFilter] = useState(true);
  const [temporaryFilter, setTemporaryFilter] = useState(true);

  const [subnicheFilter, setSubnicheFilter] = useState(false);
  const [postalFilter, setPostalFilter] = useState(false);
  const [specialisatieFilter, setSpecialisatieFilter] = useState(false);
  const [advertisingLoaded, setAdvertisingLoaded] = useState(false);

  const [subnicheFilterAll, setSubnicheFilterAll] = useState(false);
  const [nicheFilterAll, setNicheFilterAll] = useState(false);
  const [pagination, setPagination] = useState(false);

  //{ getTxt('jobs', 'brussel') }

  const [postalName, setPostalName] = useState({1: getTxt('jobs', 'brussel'), 2: getTxt('jobs', 'antwerp'), 3 : getTxt('jobs', 'limburg'), 4: getTxt('jobs', 'luik'), 5: getTxt('jobs', 'namen'), 6: getTxt('jobs', 'luxemburg'), 7: getTxt('jobs', 'henegouwen'), 8: getTxt('jobs', 'brussel'), 9: getTxt('jobs', 'east_flanders') })
  const [regionsList, setRegionsList] = useState([
    {value: '1000', label: getTxt('jobs', 'brussel')}, 
    {value: '2000', label: getTxt('jobs', 'antwerp')}, 
    {value: '3000', label: getTxt('jobs', 'limburg')}, 
    {value: '4000', label: getTxt('jobs', 'luik')}, 
    {value: '5000', label: getTxt('jobs', 'namen')}, 
    {value: '6000', label: getTxt('jobs', 'luxemburg')}, 
    {value: '7000', label: getTxt('jobs', 'henegouwen')}, 
    {value: '8000', label: getTxt('jobs', 'west_flanders')}, 
    {value: '9000', label: getTxt('jobs', 'east_flanders') }
  ]);


  const [popup, setPopup] = useState(false);

  const getJobData = (page) => {
    setJobs(false);

    let new_section = (section == 'consultants') ? 'consult-jobs' : (section == 'freelancers') ? 'freelance-jobs' :'jobs';

    let populate_jobs = '?populate=*&pagination[pageSize]=200&sort[1]=id%3Adesc&locale='+getCurrentLanuage();
    fetch(process.env.REACT_APP_STRAPI+"/api/"+new_section+populate_jobs+"&pagination[page]="+page)
      .then(res => res.json()).then(jobs => {
        console.log(jobs);
        setPagination(jobs.meta.pagination);
        let _niches = [];
        let _main_niches = [];
        let _specialisatie = [];
        let _postal_codes = [];
        jobs.data.map((val, key) => {
          if(val.attributes.niche.data){
            let typeBedrijf = val.attributes.typeBedrijf.data ? val.attributes.typeBedrijf.data.attributes.category : '';            
            let nicheSpecialisatie = val.attributes.nicheSpecialisatie.data ? val.attributes.nicheSpecialisatie.data.attributes.specialisatie : '';
            _niches.push({ 
              niche: val.attributes.niche.data.attributes.nicheTitle, 
              subniche: typeBedrijf,
              specialisatie : nicheSpecialisatie,
              niche_slug: slugify(val.attributes.niche.data.attributes.nicheTitle),
              subniche_slug: slugify(typeBedrijf),
              specialisatie_slug: slugify(nicheSpecialisatie),
              postal_code: val.attributes.postcode ? val.attributes.postcode : false
            });
            _main_niches.push({
              niche: val.attributes.niche.data.attributes.nicheTitle,
              niche_slug: slugify(val.attributes.niche.data.attributes.nicheTitle),
            })
            _specialisatie.push({
              specialisatie: nicheSpecialisatie,
              specialisatie_slug: slugify(nicheSpecialisatie),
            })
            if(val.attributes.postcode){
              let __postal_codes = parseInt(val.attributes.postcode.trim().charAt(0))+'000';
              _postal_codes.push(__postal_codes);
            }
          }
        })
        _niches = _.uniq(_niches, 'subniche_slug');
        _main_niches = _.uniq(_main_niches, 'niche_slug');
        _specialisatie = _.uniq(_specialisatie, 'specialisatie_slug');
        _postal_codes = _.uniq(_postal_codes, false);

        setPostalCodes(_postal_codes);
        setNiches(_main_niches);
        setSubniches(_niches);


        let all_subniches_filter = [];
        _niches.forEach((s_v, s_k) => {
          all_subniches_filter.push(s_v.subniche_slug)
        });
        setSubnicheFilterAll(all_subniches_filter);

        let all_niches_filter = [];
        _main_niches.forEach((s_v, s_k) => {
          all_niches_filter.push(s_v.niche_slug)
        })
        setNicheFilterAll(all_niches_filter);
        setJobs(jobs.data);
    })
  }


  useEffect(() => {
    let new_section = (section == 'consultants') ? 'consult' : section;
    let populate = '?populate=*&pagination[pageSize]=1000&locale='+getCurrentLanuage();
    fetch(process.env.REACT_APP_STRAPI+"/api/"+section+"-jobs"+populate)
      .then(res => res.json()).then(content => {
        setContent(content.data.attributes);
    })
      let _page = pagination ? pagination.page : 1;
      getJobData(_page);
    
    fetch(process.env.REACT_APP_STRAPI+"/api/teams"+populate+'&sort=id')
      .then(res => res.json()).then(team => {
        if(nicheFilter && subnicheFilter && postalFilter){
          let _found = false;
          team.data.map((member, key) => {
            if(member.attributes.niche_categories.data){
              member.attributes.niche_categories.data.map((_niche, _k) => {
                if((slugify(_niche.attributes.category) == subnicheFilter) && member.attributes.postalCode.includes(postalFilter)){
                  _found = member;
                }
              })
              if(!_found) {
                let random = Math.floor(Math.random() * 2)
                setExpert(team.data[random]);
              } else {
                setExpert(_found);
              }
            }
          })
        } else {
          let random = Math.floor(Math.random() * 2)
          setExpert(team.data[random]);
        }        
      })
   }, [section, nicheFilter, postalFilter, subnicheFilter]);

  useEffect(() => {
    setSubnicheFilter(false); setPostalFilter(false);
  }, [nicheFilter])

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(validateFields()){
      let data = { email: email, type: "jobs" };
      try {
        const response = await axios.post(process.env.REACT_APP_STRAPI+'/api/jobalerts', { data: data });
        setThanks(true);
      } catch (error) {
        setError(error);
      }
    }
  }

  useEffect(() => {
    setTimeout(()=> {
      //setPopup(true);
    }, 15000)
  }, []);

  const [error, setError] = useState(false);
  const [thanks, setThanks] = useState(false);
  const validateFields = () => {
    setError(false);
    if(email == ''){
      setError('Gelieve alle velden correct in te vullen');
      return false;
    } else {
      return true;
    }
  }

  const slugify = (str) => {
    return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
  }

  const checkNiche = (_niche, _subniche, _postalcode, _specialisatie, _permanent, _temporary) => {
    let ____postalCode = _postalcode;
    _postalcode = parseInt(_postalcode.trim().charAt(0))+'000';
    if(typeof _permanent !== null && _permanent === false && temporaryFilter === false){
      return false;
    }
    if(typeof _temporary !== null && _temporary === false && permanentFilter === false){
      return false;
    }

    if((!nicheFilter && !subnicheFilter && !specialisatieFilter && !postalFilter)){
      return true;
    }
    if((nicheFilter && !subnicheFilter && !specialisatieFilter && !postalFilter) && (nicheFilter == slugify(_niche))) {
      return true;
    }
    if((!nicheFilter && !subnicheFilter && !specialisatieFilter && postalFilter) && postalFilter.includes(_postalcode)) {
      return true;
    }
    if((!nicheFilter && subnicheFilter && !specialisatieFilter && !postalFilter) && subnicheFilter.includes(slugify(_subniche))) {
      return true;
    }
    if((!nicheFilter && !subnicheFilter && specialisatieFilter && !postalFilter) && specialisatieFilter.includes(slugify(_specialisatie)) ) {
      return true;
    }
    if((nicheFilter && !subnicheFilter) && (nicheFilter == slugify(_niche))){
      return true;
    }
    if((nicheFilter && subnicheFilter && !postalFilter && !specialisatieFilter) && (nicheFilter == slugify(_niche) && subnicheFilter.includes(slugify(_subniche)) )){
      return true;
    }
    if((nicheFilter && subnicheFilter && postalFilter && !specialisatieFilter) && (nicheFilter == slugify(_niche) && subnicheFilter.includes(slugify(_subniche)) && postalFilter.includes(_postalcode) )){
      if(!specialisatieFilter || (specialisatieFilter && specialisatieFilter.includes(slugify(_specialisatie)) )) {
        return true;
      }
    }
    if((nicheFilter && subnicheFilter && specialisatieFilter) && (nicheFilter == slugify(_niche) && subnicheFilter.includes(slugify(_subniche)) && specialisatieFilter.includes(slugify(_specialisatie) ) )){
      //console.log('APLE', nicheFilter, subnicheFilter, specialisatieFilter, postalFilter, _postalcode, ____postalCode);
      if(!postalFilter || (postalFilter && postalFilter.includes(_postalcode ) )) {
        return true;
      }
    }

    return false;
  }

  const optionsSubNiches =  (options) => {
    let _options = [];
    options.map((val, key) => {
      _options.push({ value: val.subniche_slug, label: val.subniche })
    })
    return _options;
  }

  const optionsPostal =  (options) => {
    let _options = [];
    let _postal_codes = '';
    options.map((val, key) => {
      _postal_codes = val.postal_code.split(",");
      _postal_codes.forEach((pc) => {
        pc = parseInt(pc.trim().charAt(0))+'000';
        _options.push({ value: pc, label: postalName[parseInt(pc.charAt(0))] })
      });
      
    })
    _options = _.uniq(_options, 'value');
    return _options;
  }

  const optionsSpecialisaties =  (options) => {
    let _options = [];
    options.map((val, key) => {
      let _specia = val.attributes.nicheSpecialisatie.data.attributes.specialisatie;
      _options.push({ value: slugify(_specia), label: _specia })
    })
    _options = _.uniq(_options, 'value');
    return _options;
  }

  const changeSubnicheFilter = (options) => {
    let _options = [];
    options.map((_val, _key) => {
      _options.push(_val.value);
    })
    _options = _options.length ? _options : false;
    setSubnicheFilter(_options);
  }

  const toggleSubnicheFilter = (option) => {
    let _subniche = subnicheFilter ? subnicheFilter : [];
    const index = _subniche.indexOf(option);
    if (index > -1) {
      _subniche.splice(index, 1);
    } else {
      _subniche.push(option);
    }
    _subniche = (_subniche.length > 0) ? _subniche : false; 

    //console.log((subnicheFilter && _.contains(subnicheFilter, option)));
    setSubnicheFilter(_subniche);
    console.log(subnicheFilter, option);
  }

  const changePostalFilter = (options) => {
    let _options = [];
    options.map((_val, _key) => {
      _options.push(_val.value);
    })
    _options = _options.length ? _options : false;
    setPostalFilter(_options);
  }

  const changeSpecialisatieFilter = (options) => {
    let _options = [];
    options.map((_val, _key) => {
      _options.push(_val.value);
    })
    _options = _options.length ? _options : false;
    setSpecialisatieFilter(_options);
  }

  const toggleSpecialisatieFilter = (option) => {
    let _specialisatie = specialisatieFilter ? specialisatieFilter : [];
    const index = _specialisatie.indexOf(option);
    if (index > -1) {
      _specialisatie.splice(index, 1);
    } else {
      _specialisatie.push(option);
    }
    _specialisatie = (_specialisatie.length > 0) ? _specialisatie : false; 
    setSpecialisatieFilter(_specialisatie);
  }

  const renderAdvertising = (key) => {
    if(key == 4){
      return (<article className="job advertising" key={ key }>
        <h2>{ getTxt('jobs', 'notFound') }</h2>
        <h3>{ getTxt('jobs', 'notFoundTxt') }</h3>
        <a href="#" onClick={ () => setPopup(true) } className="btn-main">{ getTxt('jobs', 'btnFill') }</a>
      </article>);
    } 
  }

  let total_spaces = 0;
  return (
    (jobs && content) ? (
      <div className="jobs">
        { popup && (
          <div className="popup">
            <div className="popup-background"  onClick={ () => { setPopup(false) } } />
            <div className="popup-inner">
              <JobalertForm title={ "Vul je gegevens in" } />
            </div>
          </div>
        ) }
        <section className="headline less-margin">
          <div className="content">
            <div className="half-text">
              <h1 style={{ backgroundColor: 'transparent', paddingLeft: 0}}><span>{ content.title.split(":")[0] }</span><span>{ content.title.split(":")[1] }</span></h1>
              <span className="intro-text" dangerouslySetInnerHTML={{__html: content.description }} />
            </div>
          </div>
        </section>
        <div className="content">
          <ul className="pager">
            { pagination && Array.from({ length: pagination.pageCount }).map((_, index) => (
              <li key={index} onClick={()=>getJobData(index+1)} className={ (index+1 == pagination.page) ? 'active' : '' }>{index + 1}</li>
            ))}
          </ul>
          <section className="job-section" style={{ paddingTop: 10 }}>

              <div className="job-filters">
                <h4>{ getTxt('jobs', 'filter') }</h4>
                <div className="filter" style={{ paddingBottom: 0, marginBottom: 10 }}>
                  <ul className="list-niches niches-menu">
                    {
                      niches.map((value, key) => {
                        return <div key={ key }><li className={ (value.niche_slug == nicheFilter) ? 'active btn-niche btn-'+value.niche_slug : 'btn-niche btn-'+value.niche_slug } onClick={ () => setNicheFilter(nicheFilter ? false : value.niche_slug) } key={ key }>{ value.niche }</li><i>({ jobs.filter(function(_val) { return (slugify(_val.attributes.niche.data.attributes.nicheTitle) == value.niche_slug)  }).length } resultaten)</i></div>
                      })
                    }
                  </ul>
                </div>
                {
                  nicheFilter && (
                    <>
                      <h4>{ getTxt('jobs', 'chooseCompany') }</h4>
                      <div className="filter">
                        {
                          optionsSubNiches(subniches.filter(function(value) {
                            return value.niche_slug == nicheFilter })
                          ).map((value, key) => {
                            return (<React.Fragment key={key}><Tickbox checked={ (subnicheFilter && _.contains(subnicheFilter, value.value)) } onChange={ () => toggleSubnicheFilter(value.value) } label={ value.label } /></React.Fragment>)
                          })
                        }
                        {/*<Select className="react-select-container" placeholder="Zoek op type bedrijf" onChange={ (_val) => { changeSubnicheFilter(_val) } } isMulti  classNamePrefix="react-select" options={optionsSubNiches(subniches.filter(function(value) {return value.niche_slug == nicheFilter }))} />*/}
                      </div>
                    </>
                  )
                }
                {/*
                  (!nicheFilter && nicheFilterAll && !specialisatieFilter) && (
                    <>
                      <h4>{ getTxt('jobs', 'typeCompany') }</h4>
                      <div className="filter">
                        {
                          subniches.map((value, key) => {
                            return (<React.Fragment key={key}>
                              <Tickbox checked={ (subnicheFilter && _.contains(subnicheFilter, value.value)) } onChange={ () => toggleSubnicheFilter(value.subniche_slug) } label={ value.subniche } />
                            </React.Fragment>)
                          })
                        }
                      </div>
                    </>
                  )
                */}
                {
                  subnicheFilter && (
                    <>
                      <h4>{ getTxt('jobs', 'chooseSpecial') }</h4>
                      <div className="filter">
                        {
                          optionsSpecialisaties(jobs.filter(function(__value) { 
                            let subN = slugify(__value.attributes.typeBedrijf.data.attributes.category);
                            return (subnicheFilter.includes(subN)) 
                          })).map((value, key) => {
                            return (<React.Fragment key={key}><Tickbox checked={ (specialisatieFilter && _.contains(specialisatieFilter, value.value)) } onChange={ () => toggleSpecialisatieFilter(value.value) } label={ value.label } /></React.Fragment>)
                          })
                        }
                        {/*
                        <Select className="react-select-container" placeholder="Zoek op type bedrijf" onChange={ (_val) => { changeSpecialisatieFilter(_val) } } isMulti  classNamePrefix="react-select" options={optionsSpecialisaties(jobs.filter(function(__value) { 
                            let subN = slugify(__value.attributes.typeBedrijf.data.attributes.category);
                            return (subnicheFilter.includes(subN)) 
                          }))} 
                        />*/}
                      </div>
                    </>
                  )
                }
                {/*
                  (!subnicheFilter && subnicheFilterAll && !nicheFilter) && (
                    <>
                      <h4>{ getTxt('jobs', 'chooseSpecial') }</h4>
                      <div className="filter">
                        {
                          optionsSpecialisaties(jobs.filter(function(__value) { 
                            let subN = slugify(__value.attributes.typeBedrijf.data.attributes.category);
                            return (subnicheFilterAll.includes(subN)) 
                          })).map((value, key) => {
                            return (
                              <React.Fragment key={ key }>
                                <Tickbox  checked={ (specialisatieFilter && _.contains(specialisatieFilter, value.value)) } onChange={ () => toggleSpecialisatieFilter(value.value) } label={ value.label } />
                              </React.Fragment>
                            )
                          })
                        }
                      </div>
                    </>
                  )
                */}
                <>
                  <h4>{ getTxt('jobs', 'choosePeriod') }</h4>
                  <div className="filter" style={{ paddingBottom: 20, marginBottom: 10 }}>
                    <Tickbox checked={ permanentFilter } onChange={ () => setPermanentFilter(!permanentFilter) } label={ getTxt('jobs', 'permanent') } />
                    <Tickbox checked={ temporaryFilter } onChange={ () => setTemporaryFilter(!temporaryFilter) } label={ getTxt('jobs', 'temporary') } />
                  </div>
                </>
                
                <>
                  <h4>{ getTxt('jobs', 'chooseRegion') }</h4>
                  <div className="filter">
                    <Select className="react-select-container" placeholder={ getTxt('jobs', 'searchRegion') } onChange={ (_val) => { changePostalFilter(_val) } } isMulti  classNamePrefix="react-select" options={regionsList} 
                    />
                  </div>
                </>
                  
                               
                <div className="filter hideonmobile">
                  <div className="jobalert-filter">
                    <h4>Jobalert</h4>
                    <h5> -</h5>
                  </div>
                  <span className="description">
                    { getTxt('jobs', 'jobAlertTxt') }
                  </span>
                  <a href="#" onClick={ () => setPopup(true) } className="btn-main">{ getTxt('jobs', 'btnFill') }</a>
                </div>
                { expert && (
                  <Member data={ expert.attributes } />
                ) }
              </div>
            


              <div className="job-jobs">

                {
                  jobs.map((value, key) => {
                    if( value.attributes.typeBedrijf.data && 
                        checkNiche(
                          value.attributes.niche.data.attributes.nicheTitle, 
                          value.attributes.typeBedrijf.data.attributes.category, 
                          value.attributes.postcode, 
                          value.attributes.nicheSpecialisatie.data.attributes.specialisatie,
                          value.attributes.permanent,
                          value.attributes.temporary
                        )
                      ){
                      total_spaces = total_spaces + 1;
                      return (
                        <React.Fragment key={key}>
                          <article className="job" key={ key }>
                            <div className="center">
                              <h4>{ value.attributes.title }</h4>
                              <span className="description">
                                { value.attributes.introText }
                              </span>
                            </div>
                            <div className="top">
                              { value.attributes.niche.data && <span className="tag yellow">{ value.attributes.niche.data.attributes.nicheTitle }</span> }
                              { value.attributes.typeBedrijf.data && <span className="tag transparent">{ value.attributes.typeBedrijf.data.attributes.category }</span> }
                              {/* value.attributes.nicheSpecialisatie.data && <span className="tag transparent">{ value.attributes.nicheSpecialisatie.data.attributes.specialisatie }</span> */}
                            </div>
                            <div className="subline">
                              {/*<span className="date">{ moment(value.attributes.publishedAt).format('MMM D, YYYY') }</span>*/}
                              <span className="location">{ (value.attributes.rewrite_region && value.attributes.rewrite_region !='') ? value.attributes.rewrite_region : postalName[parseInt(value.attributes.postcode.charAt(0))] }</span>
                              <Link className="btn-watch" to={`/${section}/jobs/${value.id}` }>
                                { getTxt('jobs', 'btnJob') } <FeatherIcon icon="arrow-right" />
                              </Link>
                            </div>
                          </article>
                          { renderAdvertising(total_spaces) }
                        </React.Fragment>
                      )
                    }
                  })
                }
              </div>
              
          </section>
        </div>
        
      </div>
    ) : <Loading />
  );
}

export default Jobs;