import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import shape from '@assets/images/shape.svg';
import shape_dark from '@assets/images/logo-black.svg';
import shape_yellow from '@assets/images/logo-yellow.svg';
import Footer from "@components/Layout/Footer";
import { getCurrentLanuage, getTxt, setCurrentLanguage } from '@data/locales';
import CookiePopup from "@components/CookiePopup";

const MainLayout =({children, page, section}) => {
  import('./style.scss');

  const urlParams = new URLSearchParams(window.location.search);

  const [activeHeader, setActiveHeader] = useState(false);

  useEffect(() => {
    if(urlParams.get('lang') == 'nl'){
      setCurrentLanguage('en');
    }
    if(urlParams.get('lang') == 'fr'){
      setCurrentLanguage('fr-BE');
    }
  }, []);

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    window.location.reload();
  }

  const main_menu = {
    freelancers: [
      { section: 'freelancers', page: 'aboutus', link: 'over-ons', title: getTxt('mainMenu', 'aboutus')},
      { section: 'freelancers', page: 'services', link: 'services', title: getTxt('mainMenu', 'jdjfor')},
      { section: 'freelancers', page: 'jobs', link: 'jobs', title: getTxt('mainMenu', 'projects')},
      { section: 'freelancers', page: 'team', link: 'onze-ploeg', title: getTxt('mainMenu', 'team')},
      { section: 'freelancers', page: 'contact', link: 'contact', title: getTxt('mainMenu', 'contact')},
    ], 
    consultants: [
      { section: 'consultants', page: 'aboutus', link: 'over-ons', title: getTxt('mainMenu', 'aboutus')},
      { section: 'consultants', page: 'services', link: 'services', title: getTxt('mainMenu', 'jdj')},
      { section: 'consultants', page: 'jobs', link: 'jobs', title: getTxt('mainMenu', 'projects')},
      { section: 'consultants', page: 'team', link: 'onze-ploeg', title: getTxt('mainMenu', 'team')},
      { section: 'consultants', page: 'contact', link: 'contact', title: getTxt('mainMenu', 'contact')},
    ],
    companies: [
      { section: 'companies', page: 'aboutus', link: 'over-ons', title: getTxt('mainMenu', 'aboutus')},
      { section: 'companies', page: 'services', link: 'services', title: getTxt('mainMenu', 'services')},
      { section: 'companies', page: 'team', link: 'onze-ploeg', title: getTxt('mainMenu', 'team')},
      { section: 'companies', page: 'contact', link: 'contact', title: getTxt('mainMenu', 'contact')},
    ],
    werknemers: [
      { section: 'werknemers', page: 'aboutus', link: 'over-ons', title: getTxt('mainMenu', 'aboutus')},
      { section: 'werknemers', page: 'services', link: 'services', title: getTxt('mainMenu', 'services')},
      { section: 'werknemers', page: 'jobs', link: 'jobs', title: getTxt('mainMenu', 'jobs')},
      { section: 'werknemers', page: 'team', link: 'onze-ploeg', title: getTxt('mainMenu', 'team')},
      { section: 'werknemers', page: 'contact', link: 'contact', title: getTxt('mainMenu', 'contact')},
    ],
    werkgevers: [
      { section: 'werkgevers', page: 'aboutus', link: 'over-ons', title: getTxt('mainMenu', 'aboutus')},
      { section: 'werkgevers', page: 'services', link: 'services', title: getTxt('mainMenu', 'services')},
      { section: 'werkgevers', page: 'jobs', link: 'jobs', title: getTxt('mainMenu', 'jobs')},
      { section: 'werkgevers', page: 'team', link: 'onze-ploeg', title: getTxt('mainMenu', 'team')},
      { section: 'werkgevers', page: 'contact', link: 'contact', title: getTxt('mainMenu', 'contact')},
    ],
    werken: [
      { section: 'werken', page: 'aboutus', link: 'over-ons', title: getTxt('mainMenu', 'aboutus')},
      { section: 'werken', page: 'vacanvies', link: 'solliciteer-nu', title: getTxt('mainMenu', 'vacancies')},
      { section: 'werken', page: 'team', link: 'onze-ploeg', title: getTxt('mainMenu', 'team')},
      { section: 'werken', page: 'contact', link: 'contact', title: getTxt('mainMenu', 'contact')},
    ]
  }

  return(
    <div id="wrapper" className={`section-${section} page-${page}`}>
      <CookiePopup />
      <div className="langmenu hideonmobile">
        <span onClick={ () => changeLanguage('en') } className={'lang ' + (getCurrentLanuage() == 'en' ? 'active' : '') }>NL</span>
        <span onClick={ () => changeLanguage('fr-BE') } className={'lang ' + (getCurrentLanuage() == 'fr-BE' ? 'active' : '') }>FR</span>
      </div>
      <div className="content">
        <header className={ activeHeader ? 'active' : '' }>
          <Link onClick={ () => { setActiveHeader(false) }} to={"/"+section} className="logo">
            <img className="logo" alt="Jorissen & De Jonck" src={ (section == 'werken') ? (page == "home") ? shape_yellow : shape_dark  : shape } width="30" />
          </Link>
          <div className="menu-wrapper">
            <menu className="menu-main">
              { main_menu[section].map((value, key) => {
                return <Link onClick={ () => { setActiveHeader(false) }} to={'/'+value.section+'/'+value.link} className={ (page == value.page) ? 'active' : '' } >{ value.title }</Link>
              })}
            </menu>
            { (section == 'freelancers' || section == 'companies' || section == 'consultants') && (
              <menu className="menu-sections">
                <Link onClick={ () => { setActiveHeader(false) }} to="/consultants" className={ (section=='consultants') ? 'btn-main' : '' }>{ getTxt('mainMenu', 'consultants') }</Link>
                <Link onClick={ () => { setActiveHeader(false) }} to="/freelancers" className={ (section=='freelancers') ? 'btn-main' : '' }>{ getTxt('mainMenu', 'freelancers') }</Link>
                <Link onClick={ () => { setActiveHeader(false) }} to="/companies" className={ (section=='companies') ? 'btn-main' : '' }>{ getTxt('mainMenu', 'companies') }</Link>
              </menu>
            ) }
            { (section == 'werknemers' || section == 'werkgevers' || section == 'werken') && (
              <menu className="menu-sections">
                <Link onClick={ () => { setActiveHeader(false) }} to="/werknemers" className={ (section=='werknemers') ? 'btn-main' : '' }>{ getTxt('mainMenu', 'werknemers') }</Link>
                <Link onClick={ () => { setActiveHeader(false) }} to="/werkgevers" className={ (section=='werkgevers') ? 'btn-main' : '' }>{ getTxt('mainMenu', 'employers') }</Link>
                <Link onClick={ () => { setActiveHeader(false) }} to="/werken" className={ (section=='werken') ? 'btn-main' : '' }>{ getTxt('mainMenu', 'jdj') }</Link>
              </menu>
            ) }
            <div className="langmenu-mobile showonmobile">
              <span onClick={ () => changeLanguage('en') } className={'lang ' + (getCurrentLanuage() == 'en' ? 'active' : '') }>NL</span>
              <span onClick={ () => changeLanguage('fr-BE') } className={'lang ' + (getCurrentLanuage() == 'fr-BE' ? 'active' : '') }>FR</span>
            </div>
          </div>
          <div className="mobile-menu" onClick={ () => { setActiveHeader(!activeHeader) } }>
            <div></div><div></div><div></div>
          </div>
        </header>
      </div>
      <main>{ children }</main>
      <Footer page={ section+'-'+page } />
    </div>
  )
}

export default MainLayout;